/*
 * 업무구분: 수익증권>공통>본인인증
 * 화 면 명: MSPFS510P
 * 화면설명: 본인인증 POPUP
 * 작 성 일: 2023.03.21
 * 작 성 자: 송진의
 */
<template>
  <!-- popup -->
  <mo-modal class="fts-modal medium pop-beforeMain pop-verification" ref="verification" title="본인 인증">
    <template>
      <div class="wrap-modalcontents">
        <mo-tab-box :defaultIdx=1 @tab-change="fn_TabChangeHandler" ref="tabVerification">
          <!-- 휴대폰 -->
          <mo-tab-content :idx=1  label="휴대폰">
            <tabMSPFS511D :pAthntData="lv_athntData" @setFS511D="fn_SetFS511D" ref="tabMSPFS511D" />
          </mo-tab-content>

          <!-- 신용카드 -->
          <mo-tab-content :idx=2  label="신용카드">
            <tabMSPFS512D :pAthntData="lv_athntData" @setFS512D="fn_SetFS512D" ref="tabMSPFS512D" />
          </mo-tab-content>
        </mo-tab-box>
      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" v-if="lv_isTestBtn" @click="fn_AddAthnt()">인증완료</mo-button>
        <!-- <mo-button point size="large" @click="fn_Close()">닫기</mo-button> -->
      </div>
    </template>
  </mo-modal>  
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import MSPFS511D from '~/src/ui/fs/MSPFS511D'
import MSPFS512D from '~/src/ui/fs/MSPFS512D'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPFS510P",
  props: {
    popupObj: {type:Object, default:null},
  },
  screenId: "MSPFS510P",  
  components: {
    'tabMSPFS511D' : MSPFS511D,
    'tabMSPFS512D' : MSPFS512D,
  },  
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    if (process.env.NODE_ENV === 'production') {
      this.lv_isTestBtn = false
    }
  },
  mounted() {    
    this.$bizUtil.insSrnLog("MSPFS510P")    
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      lv_athntData: {
        id1: {},
        id2: {},
        id3: {},
        id4: {},
        id5: {},
        id6: {},
        id7: {},
        id8: {},
        id9: {},
        id10: {},
      },  // 인증구분 설정
      reportData: '',      
      slctTabIdx : '1', // 현재 선택된 탭 인덱스 값 1:휴대폰, 2:신용카드
      lv_isTestBtn: true,
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {    
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /*********************************************************
      * Function명: fn_TabChangeHandler
      * 설명: 탭 핸드러
      * Params: idx (1: 휴대폰인증, 2: 신용카드인증)
      *********************************************************/
    fn_TabChangeHandler(idx){
      this.slctTabIdx = idx
      if(idx === 1){
        this.$refs.tabMSPFS511D.fn_Init(2, this.lv_athntData)
      } else {
        this.$refs.tabMSPFS512D.fn_Init(2, this.lv_athntData)
      }
      console.log("this.slctTabIdx: ", idx)
      console.log("this.lv_athntData: ", this.lv_athntData)
    },
    /*********************************************************
      * Function명: fn_SetFS511D
      * 설명: 휴대폰본인인증 CallBack
      * Params: type (0: 변수 초기화, 1: 추가인증, 2: 인증완료) , pData (인증 데이터)      
      *********************************************************/
    fn_SetFS511D(type, pData) {
      console.log('fn_SetFS511D...', type, pData)
      this.fn_SetData(type, pData)      
    },
    /*********************************************************
      * Function명: fn_SetFS512D
      * 설명: 신용카드 CallBack
      * Params: type (0: 변수 초기화, 1: 추가인증, 2: 인증완료) , pData (인증 데이터)      
      *********************************************************/
    fn_SetFS512D(type, pData) {
      console.log('fn_SetFS512D...', type, pData)
      this.fn_SetData(type, pData)
    },
    /*********************************************************
      * Function명: fn_SetData
      * 설명: 인증데이터 설정
      * Params: type (0: 변수 초기화, 1: 추가인증, 2: 인증완료) , pData (인증 데이터)      
      *********************************************************/
    fn_SetData(type, pData) {
      this.lv_athntData = pData      

      if(type == 0) {
        return
      }

      if(type == 1 || type == 2) {
        if(this.fn_ValidateAthnt()){
          let scrnId = this.$route.name // 팝업 아닌, 메인화면 부분 확인          
          if(!this.$bizUtil.isEmpty(scrnId)) {
            if(scrnId.substr(3,2).toLowerCase() === 'fs') {
              this.$bizUtil.fsUtils.saveAgrInfo(this, pData)
            } else {
              this.$bizUtil.tsUtils.saveAgrInfo(this, pData)
            }
          }

          if(type == 2) {
            this.$emit('fs-popup-callback', 'MSPFS510P', pData)
            this.fn_Close()
          }
        } else {
          this.$addSnackbar({text: '인증구분을 선택하여, 인증을 완료해주세요', error: true})
        }
      }
    },
    /*********************************************************
      * Function명: fn_ValidateAthnt
      * 설명: 인증구분 확인
      * Params: N/A
      * Return: true : 인증완료, false : 미완료
      *********************************************************/    
    fn_ValidateAthnt() {
      let obj = {}        
      for(const item in this.lv_athntData){
        obj = {}
        obj = this.lv_athntData[item]
        if(obj.value) {
          return true          
        }
      }
      return false
    },
    /*********************************************************
      * Function명: fn_ValidateAthnt
      * 설명: 인증구분 확인
      * Params: type (0: 초기화, 2: 추가 인증)
      * Return: N/A
      *********************************************************/
    fn_Init(type = 0) {      
      this.lv_athntData = { 
        id1: {},
        id2: {},
        id3: {},
        id4: {},
        id5: {},
        id6: {},
        id7: {},
        id8: {},
        id9: {},
        id10: {}
      }
      this.$refs.tabVerification.setIdx(1)      
      this.$refs.tabMSPFS511D.fn_Init(type)
      this.$refs.tabMSPFS512D.fn_Init(type)
      this.$refs.verification.open()           
    },
    /*********************************************************
      * Function명: fn_Open
      * 설명: 팝업 열기 (초기화)
      * Params: N/A
      * Return: N/A
      *********************************************************/
    fn_Open(jopTyp = '') {
      console.log('[MSPFS510P] fn_Open...')
      if( !FSCommUtil.gfn_isNull(this.popupObj) ) {
        if( !FSCommUtil.gfn_isNull(this.popupObj.reportData) ){
          this.reportData = this.popupObj.reportData
        }
      }
      
      // 본인인증 팝업 오픈 시 고객 정보 설정
      if(jopTyp === 'fs'){
        this.$bizUtil.fsUtils.setCvrNoByCustNo(this)
      }
      this.fn_Init()      
    },
    /*********************************************************
      * Function명: fn_Close
      * 설명: 팝업 닫기
      * Params: N/A
      * Return: N/A
      *********************************************************/
    fn_Close() {      
      this.$refs.verification.close()
      this.$refs.tabMSPFS511D.closeDropdown()
      this.$refs.tabMSPFS512D.closeDropdown()
    },
    // 인증완료(테스트)
    fn_TestConfirm() {
      this.$emit('fs-popup-callback', 'MSPFS510P', true)
      this.$refs.verification.close()
      this.$refs.tabMSPFS511D.closeDropdown()
      this.$refs.tabMSPFS512D.closeDropdown()
    },    
    fn_AddAthnt() {      
      if(this.slctTabIdx == 1){
        this.$refs.tabMSPFS511D.fn_AddAthnt()
      } else {
        this.$refs.tabMSPFS512D.fn_AddAthnt()
      }
    },
    addSnackbar() {
      this.$refs.stackableSnackbar.show({text:this.content});
    },
  }
};
</script>
<style scoped>
</style>