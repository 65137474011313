/*
 * 업무구분: 수익증권>공통>본인인증(탭:신용카드)
 * 화 면 명: MSPFS512D
 * 화면설명: 본인인증 POPUP
 * 작 성 일: 2023.03.27
 * 작 성 자: 송진의
 */
<template>
  <ur-box-container>
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="fts">
      <div class="wrap-table mt16">
        <mo-validate-watcher ref="vWatcher">
        <table class="table">
          <tbody>
            <tr>
              <th>성명</th>
              <td>
                <div class="wrap-input row">
                  <mo-text-field v-model="custCardVO.custNm" placeholder="입력하세요" class="w245" :rules="validateRule"/>
                </div>
              </td>
            </tr>
            <tr>
              <th>생년월일</th>
              <td>
                <div class="wrap-input row">
                    <mo-decimal-field numeric v-model="custCardVO.knbFrno" class="w130" placeholder="6자리" mask="######" @input="fn_BirthFocus" :rules="validateRuleKnbFrno" />
                    <mo-decimal-field numeric ref="knbBkno" v-model="custCardVO.knbBkno" class="mw30" placeholder=" " mask="#" :rules="validateRule" />
                    <span class="idNum-skip">******</span>
                    <!-- <mo-button v-if="!authConfirm" @click="fn_Validation">인증하기</mo-button> -->
                </div>
              </td>
            </tr>
            <tr>
              <th>인증구분</th>
              <td>
                <div class="wrap-input chkLeft" v-if="lv_partTyp === 'fs'">
                  <mo-checkbox class="type02" v-model="lv_athntData.id1.value" :disabled="lv_athntData.id1.disabled" @input="fn_SetAgrTime(1)"> {{lv_athntTxt.fs[0]}} </mo-checkbox>
                  <mo-checkbox class="type02" v-model="lv_athntData.id2.value" :disabled="lv_athntData.id2.disabled" @input="fn_SetAgrTime(2)"> {{lv_athntTxt.fs[1]}} </mo-checkbox>
                  <mo-checkbox class="type02" v-model="lv_athntData.id3.value" :disabled="lv_athntData.id3.disabled" @input="fn_SetAgrTime(3)"> {{lv_athntTxt.fs[2]}}</mo-checkbox>
                  <mo-checkbox class="type02" v-model="lv_athntData.id4.value" :disabled="lv_athntData.id4.disabled" @input="fn_SetAgrTime(4)"> {{lv_athntTxt.fs[3]}} </mo-checkbox>
                  <mo-checkbox class="type02" v-model="lv_athntData.id5.value" :disabled="lv_athntData.id5.disabled" @input="fn_SetAgrTime(5)"> {{lv_athntTxt.fs[4]}} </mo-checkbox>
                  <mo-checkbox class="type02" v-model="lv_athntData.id6.value" :disabled="lv_athntData.id6.disabled" @input="fn_SetAgrTime(6)"> {{lv_athntTxt.fs[5]}} </mo-checkbox>
                </div>
                <div class="wrap-input chkLeft" v-else>
                  <mo-checkbox class="type02" v-model="lv_athntData.id1.value" :disabled="lv_athntData.id1.disabled" @input="fn_SetAgrTime(1)"> {{lv_athntTxt.ts[0]}} </mo-checkbox>
                  <mo-checkbox class="type02" v-model="lv_athntData.id2.value" :disabled="lv_athntData.id2.disabled" @input="fn_SetAgrTime(2)"> {{lv_athntTxt.ts[1]}} </mo-checkbox>
                  <mo-checkbox class="type02" v-model="lv_athntData.id3.value" :disabled="lv_athntData.id3.disabled" @input="fn_SetAgrTime(3)"> {{lv_athntTxt.ts[2]}}</mo-checkbox>
                  <mo-checkbox class="type02" v-model="lv_athntData.id4.value" :disabled="lv_athntData.id4.disabled" @input="fn_SetAgrTime(4)"> {{lv_athntTxt.ts[3]}} </mo-checkbox>
                  <mo-checkbox class="type02" v-model="lv_athntData.id5.value" :disabled="lv_athntData.id5.disabled" @input="fn_SetAgrTime(5)"> {{lv_athntTxt.ts[4]}} </mo-checkbox>
                  <mo-checkbox class="type02" v-model="lv_athntData.id6.value" :disabled="lv_athntData.id6.disabled" @input="fn_SetAgrTime(6)"> {{lv_athntTxt.ts[5]}} </mo-checkbox>
                  <mo-checkbox class="type02" v-model="lv_athntData.id7.value" :disabled="lv_athntData.id7.disabled" @input="fn_SetAgrTime(7)"> {{lv_athntTxt.ts[6]}} </mo-checkbox>
                  <mo-checkbox class="type02" v-model="lv_athntData.id8.value" :disabled="lv_athntData.id8.disabled" @input="fn_SetAgrTime(8)"> {{lv_athntTxt.ts[7]}} </mo-checkbox>
                  <mo-checkbox class="type02" v-model="lv_athntData.id9.value" :disabled="lv_athntData.id9.disabled" @input="fn_SetAgrTime(9)"> {{lv_athntTxt.ts[8]}} </mo-checkbox>
                  <mo-checkbox class="type02" v-model="lv_athntData.id10.value" :disabled="lv_athntData.id10.disabled" @input="fn_SetAgrTime(10)"> {{lv_athntTxt.ts[9]}} </mo-checkbox>                    
                </div>
              </td>                
            </tr>
            <tr>
              <th>휴대폰</th>
              <td>
                <div class="wrap-input row">
                  <mo-dropdown :items="phoneItems" v-model="custCardVO.celnoFrno" class="w80" placeholder=" " ref="dropdown1" />                          
                  <mo-decimal-field numeric v-model="custCardVO.celnoBkno1" class="w80" mask="####" placeholder=" " :rules="validateRuleCelno" />
                  <mo-decimal-field numeric v-model="custCardVO.celnoBkno2" class="w80" mask="####" placeholder=" " :rules="validateRuleCelno" />
                  <mo-button class="btn-credit-verify" @click="fn_Validation()">인증하기</mo-button>                        
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        </mo-validate-watcher>
      </div>
      <div class="wrap-desc mt16">
        <p>신용카드 인증 시 주의사항</p>
        <ul>
          <li>1. 법인카드, 가족카드, 선불카드는 본인 확인이 불가합니다.</li>
          <li>2. 휴대폰에 착신전환, 수신거부, 로밍 등의 설정이 되어있는 경우 서비스 이용이 불가할 수 있습니다.</li>
          <li>3. 카드사에 등록된 고객님의 휴대폰 번호가 변경되었거나 잘못 등록된 경우, 변경 후 이용하시기 바랍니다.</li>
        </ul>
      </div>
    </ur-box-container>

    <!-- alert modal popup -->
    <mo-modal 
      ref="alertPopup"
      class="fts-modal small"     
      :title="lv_alertPopupObj.title">
      
      <div class="column align-center">
        <h2 class="title" v-if="lv_alertPopupObj.contentTitle">{{ lv_alertPopupObj.contentTitle }}</h2>
        <span class="wrap-text">
          <span v-for="(row, i) in lv_alertPopupObj.content" :key="i">
          {{ lv_alertPopupObj.content[i] }}<br v-if="i < lv_alertPopupObj.content.length"/>
          </span>
        </span>
      </div>
      <template slot="action">
        <div class="wrap-button button-page-bottom row" v-if="lv_alertPopupObj.type === 0">
          <mo-button size="large" @click="fn_AlertPopupClose()">확인</mo-button>
        </div>
        <div class="wrap-button button-page-bottom row" v-if="lv_alertPopupObj.type === 1">
          <mo-button size="large" @click="fn_AlertPopupClose()">아니오</mo-button>
          <mo-button primary size="large" @click="fn_AlertPopupConfirm()">예</mo-button>
        </div>
      </template>
    </mo-modal>
    
    <mo-snackbar ref="stackableSnackbar" :duration="3000"></mo-snackbar>
  </ur-box-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSCommUtil from "~/src/ui/fs/comm/FSCommUtil";
import MSPCM010P from '@/ui/cm/MSPCM010P' // 알뜰폰 안내
import moment from 'moment'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPFS510P",
  screenId: "MSPFS510P",
  components: {},
  props: {    
    pAthntData: {type:Object, default:null},
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {    
    this.fn_Init()
  },
  mounted() {
    this.$bizUtil.insSrnLog("MSPFS512D");
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // 휴대폰 관련
      telecomType: '',
      telecomTypes: [
        { value: 'SKT', text: 'SKT'},
        { value: 'KTF', text: 'KT' },
        { value: 'LGT', text: 'LG U+' },
        { value: 'SKTM',text: 'SKT(알뜰폰)' },
        { value: 'KTM', text: 'KT(알뜰폰)'  },
        { value: 'LGTM',text: 'LG U+(알뜰폰)'},
        { value: 'MCHK',text: '알뜰폰 확인하기'}
      ],
      prevTelecomType: '',

      // 신용카드 관련 정보
      custCardVO: {custNm: '', knbFrno: '', knbBkno: '', celno: '', celnoFrno: '',  celnoBkno1: '',  celnoBkno2: ''},
      validateRuleKnbFrno: [
        v => !!v || '필수입력항목입니다',
        v => v.length > 5 || '생년월일을 입력하세요.'
      ],   
      validateRule: [
        v => !!v || '필수입력항목입니다'
      ],
      validateRuleCelno: [
        v => !!v || '필수입력 항목입니다',
        v => v.length >= 3 || '휴대폰번호를 입력하세요.'
      ],      
      athntMthScCd: '2', // 인증수단
      contentReg: '인증되었습니다.',
      authConfirm: false,
      phoneItems: [],
      lv_alertPopupObj: {},
      lv_athntData: {},  // 인증구분 설정
      lv_partTyp: '' , // 업무구분
      lv_athntTxt: {
        fs: ['고객','대리인','조력자','은행계좌주','친권자1','친권자2'],
        ts: ['위탁자(고객)','수익자(원금)','수익자(이익)','대리인','대리인2','조력자','신탁관리인','1차수익자(원금)','1차수익자(이익)','지급청구대리인'],
      }
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    telecom() {
      let rtn = [];
      rtn.push({value: 'skt', text: 'SKT'});
      rtn.push({value: 'kt', text: 'KT'});
      rtn.push({value: 'lg', text: 'LG U+'});
      rtn.push({value: 'sktLite', text: 'SKT(알뜰폰)'});
      rtn.push({value: 'ktLite', text: 'KT(알뜰폰)'});
      rtn.push({value: 'lgLite', text: 'LG(알뜰폰)'});
      return rtn;
    },
    phoneNum01() {
      let rtn = [];
      rtn.push({value: '010', text: '010'});
      rtn.push({value: '011', text: '011'});
      return rtn;
    },
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    telecomType (value) {
      if (value === 'MCHK') { // 알뜰폰 확인하기 선택 시 팝업창 호출 (MSPCM010P 팝업)
        this.popup010 = this.$bottomModal.open(MSPCM010P, {
          properties: {
            pCustNm: this.custCardVO.custNm // 고객명 파라미터
          },
          listeners: {
            confirmPopup: (pData) => {
              this.$bottomModal.close(this.popup010)
              
              // 파라미터 세팅
              this.fn_ConfirmMSPCM010P(pData)
            }
          }
        })
      }
    },
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_SetAgrTime(idx) {      
      const athntData = this.lv_athntData['id' + String(idx)]
      const mthTxt = '신용카드'
      if(athntData.value) {
        let curDt = moment(new Date().toISOString()).format("YYYY년MM월DD일 HH:mm:ss")
        athntData.text = athntData.type + '(은)는 ' + curDt + '에 ' + mthTxt + '(으)로 인증하였습니다.'
        console.log('fn_SetAgrTime > ', athntData.text)
      } else {
        athntData.text = ''
      } 
    },
    fn_AlertPopup(obj) {
      this.lv_alertPopupObj = {}
      this.lv_alertPopupObj.type = obj.type
      this.lv_alertPopupObj.title = ''        // 사용안함    
      this.lv_alertPopupObj.contentTitle = '' // 사용안함
      this.lv_alertPopupObj.content = obj.content
      this.lv_alertPopupObj.closeFunc = function () {}
      this.lv_alertPopupObj.confirmFunc = function () {}      
      if(obj.type === 1){
        this.lv_alertPopupObj.closeFunc = obj.closeFunc
        this.lv_alertPopupObj.confirmFunc = obj.confirmFunc
      }
      this.$refs.alertPopup.open()
    },
    fn_AlertPopupClose() {
      if(!FSCommUtil.gfn_isNull(this.lv_alertPopupObj.closeFunc)){
        this.lv_alertPopupObj.closeFunc.call(this)
      }
      this.$refs.alertPopup.close()
    },
    fn_AlertPopupConfirm() {
      if(!FSCommUtil.gfn_isNull(this.lv_alertPopupObj.confirmFunc)){
        this.lv_alertPopupObj.confirmFunc.call(this)
      }
      this.$refs.alertPopup.close()
    },
    fn_AddAthnt() {      
      let lv_Vm = this
      let t_popupObj = {
        type : 1,                
        confirmFunc: function () {
          lv_Vm.fn_Init(1) // 추가 본인인증
        },
        closeFunc: function () {
          lv_Vm.$emit('setFS512D', 2, lv_Vm.lv_athntData)
        },
        content: ['인증되었습니다.', '추가로 본인인증을 진행하시겠습니까?']                
      }              
      lv_Vm.fn_AlertPopup(t_popupObj)
    },
    /*********************************************************
     * Function명: fn_Init
     * 설명: 로딩시 정보항목 초기화 및 설정
     * Params:  type (0: 변수 초기화, 1: 추가인증, 2: 탭 이동) , pData (인증 데이터)
     * Return: N/A
     *********************************************************/
    fn_Init (type = 0, pParam = null) {
      console.log('[MSPFS512D] fn_Init...')
      let lv_Vm = this      
      if(type == 0) { // 초기화        
        this.lv_partTyp = this.$route.name.substr(3,2).toLowerCase()
        let tmpAthntData = {}
        let startIdx = 1
        let endIdx = this.lv_partTyp === 'fs' ? 6 : 10
        let athntTxt = this.lv_partTyp === 'fs' ? this.lv_athntTxt.fs : this.lv_athntTxt.ts
        for(let i = startIdx ; i <= endIdx ; i++){
          tmpAthntData['id' + String(i)] = FSCommUtil.gfn_dataSet(3)
          tmpAthntData['id' + String(i)].type = athntTxt[i - 1]
          tmpAthntData['id' + String(i)].text = ''
        }
        lv_Vm.lv_athntData = Object.assign({}, tmpAthntData)
        lv_Vm.$emit('setFS512D', 0, lv_Vm.lv_athntData)
      } else if(type == 1) { // 추가 본인인증인 경우
        let obj = {}        
        for(const item in lv_Vm.lv_athntData){          
          obj = lv_Vm.lv_athntData[item]
          if(obj.value) {
            console.log('obj.value = true >', obj)
            obj.disabled = true
          }          
        }
        lv_Vm.$emit('setFS512D', 1, lv_Vm.lv_athntData)
      } else if(type == 2) { // 탭 이동 시
        if(this.$bizUtil.isEmpty(pParam)) {
          this.fn_Init()
          return
        }
        console.log('fn_Init - pParam : ', pParam)
        let obj = {}
        lv_Vm.lv_athntData = pParam
        for(const item in lv_Vm.lv_athntData){          
          obj = lv_Vm.lv_athntData[item]
          if(obj.value) {
            console.log('obj.value = true >', obj)
            obj.disabled = true
          }          
        }
      }

      // ---------------------------------------------------------------------------------
      // 공통업무코드 조회 및 설정 처리
      // ---------------------------------------------------------------------------------
      window.vue.getInstance('vue').$commonUtil.selListMassEACommCd([
          'ZA_ELTRN_MAL_DM_CD', // 이메일도메인 코드정보
        'ZA_CELPH_KNB_CD' // 휴대전화국번 코드정보
      ]).then(function () {
        // 휴대전화 앞자리 정보 세팅
        let lv_CelphCD = lv_Vm.getStore('code').getters.getState.get('ZA_CELPH_KNB_CD')

        lv_Vm.phoneItems.push({value: '0', text: '선택'})
        if (lv_CelphCD.length > 0) {
          lv_CelphCD.forEach(
            (item) => {
              let lv_Item = {}
              lv_Item.value = item.key
              lv_Item.text = item.key
              lv_Vm.phoneItems.push(lv_Item)
            }
          )
        }

        // 휴대폰번호 앞자리 초기값은 '010'
        lv_Vm.custCardVO.celnoFrno = lv_Vm.phoneItems[1].value
        lv_Vm.custCardVO.celnoBkno1 = ''
        lv_Vm.custCardVO.celnoBkno2 = ''
      })
    },
    /******************************************************************************
    * Function명 : fn_Validation
    * 설명       : 인증번호 버튼 클릭 시 유효성 체크
    ******************************************************************************/
    fn_Validation () {      
      let verifyCheck = this.fn_ValidationInput()      
      if (!verifyCheck) {
        return
      }

      let lv_Vm = this          
      if (lv_Vm.custCardVO.celnoFrno === '0') { // 핸드폰앞자리가 선택으로 되어 있는 경우
        lv_Vm.custCardVO.celnoFrno = lv_Vm.phoneItems[1].value
      }      
      
      lv_Vm.celno = lv_Vm.custCardVO.celnoFrno + lv_Vm.custCardVO.celnoBkno1 + lv_Vm.custCardVO.celnoBkno2
      let phone = lv_Vm.celno.replace(/\-/g, '')
      if (lv_Vm.$commonUtil.isMobile()) { // 모바일환경에서만 호출
        console.log('mobile =>')
        window.fdpbridge.exec('CardCertPlugin', {
          custNm : this.custCardVO.custNm,
          birth : this.$bizUtil.getBirthDateFromKnb(this.custCardVO.knbFrno + this.custCardVO.knbBkno),
          sexCd : this.$bizUtil.getGenderFromKnb(this.custCardVO.knbFrno + this.custCardVO.knbBkno).substring(0,1).toUpperCase(),    //M:남자, F:여자
          phone: phone,
          cpCode: 'RO9900000183' // 수익증권 카드인증          
        }, function (result) {
          if (result.data === 'Y') {                
            lv_Vm.fn_authFinish()
          } else {
            // lv_Vm.getStore('confirm').dispatch('ADD', '신용카드인증실패')            
            lv_Vm.fn_AlertPopup({type: 0, content: ['신용카드인증실패']})
          }
        }, function (result) {
        // 에러콜백
          // lv_Vm.getStore('confirm').dispatch('ADD', '신용카드인증실패')          
          lv_Vm.fn_AlertPopup({type: 0, content: ['신용카드인증실패']})
        })
      } else {
        // 모바일환경아닌 경우 (태블릿이니까 필요없음)
        lv_Vm.fn_authFinish()
      }
    },
    /******************************************************************************
    * Function명 : fn_authFinish
    * 설명       : 신용카드 인증 완료 후 인증유형, 인증번호 저장을 위한 함수 호출
    ******************************************************************************/
    fn_authFinish () {
      let lv_Vm = this
      let jobTyp = lv_Vm.$route.name.substr(3,2).toUpperCase()
            
      // 인증정보 저장
      let pParams = {
        custNm: lv_Vm.custNm, // 고객명
        rrn: lv_Vm.rrn, // 고객주민번호
        knb: lv_Vm.$bizUtil.isEmpty(jobTyp) ? '' : jobTyp, // CM , 담당업무코드
        selfAthntUsScCd: '03', // 본인인증용 구분코드 02:전자서명 본인인증, 03: 본인인증
        userId: lv_Vm.cnsltNo,
        athntNo: '',
        athntMthScCd: lv_Vm.athntMthScCd, // 본인인증용 구분코드
        celno: lv_Vm.celno.replace(/\-/g, '')
      }

      let trnstId = 'txTSSFS03I1'
      this.post(lv_Vm, pParams, trnstId, 'I')
        .then(function (response) {
          console.log('msgCd : ', response.msgComm.msgCd)
          if (response.body !== null && response.body.wkRslt === 0) {
            console.log('인증정보 저장 OK')
            lv_Vm.authConfirm = true
             let t_popupObj = {
                type : 1,                
                confirmFunc: function () {
                  lv_Vm.fn_Init(1)
                },
                closeFunc: function () {
                  lv_Vm.$emit('setFS512D', 2, lv_Vm.lv_athntData)
                },
                content: ['인증되었습니다.', '추가로 본인인증을 진행하시겠습니까?']                
              }              
              lv_Vm.fn_AlertPopup(t_popupObj)
          } else {
            console.log('인증정보 저장 Fail')
          }
        })
    },
    fn_ValidationInput() {
      this.$refs.vWatcher.validate();
      if (!this.$refs.vWatcher.isValid() === true) {
        this.$addSnackbar({text: this.$refs.vWatcher.validate(), error: !this.$refs.vWatcher.isValid()});
        return false
      }
      return true
    },
    /******************************************************************************
    * Function명 : fn_ConfirmMSPCM010P
    * 설명       : 알뜰폰 안내 팝업창의 '확인', '취소' 버튼 클릭 시 호출
    ******************************************************************************/
    fn_ConfirmMSPCM010P (param) {
      if (param !== null && param !== '') {
        this.telecomType = param.val
      } else { // 취소버튼 클릭 시
        this.telecomType = this.prevTelecomType
      }
    },
    addSnackbar() {
      this.$refs.stackableSnackbar.show({text:this.content});
    },
    // modal
    openModal(type) {
      this["modal"+type].open();
    },
    closeModal(type) {
      this["modal"+type].close();
    },
    closeDropdown() {
      this.$refs.dropdown1.close()
    },
    /******************************************************************************
    * Function명 : fn_BirthFocus
    * 설명       : 생년월일입력시 성별 자동포커스 이벤트 컨트롤 함수
    ******************************************************************************/
    fn_BirthFocus (event) {
      if (this.custCardVO.knbFrno.length === 6) {
        this.$refs.knbBkno.focus()
      }
    },
  }
};
</script>
<style scoped>
</style>