/*
 * 업무구분: 수익증권>공통>본인인증(탭:휴대폰)
 * 화 면 명: MSPFS511D
 * 화면설명: 본인인증 POPUP
 * 작 성 일: 2023.03.27
 * 작 성 자: 송진의
 */
<template>
  <ur-box-container>
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="fts">
      <div class="wrap-input row mt16 mb6">
        <mo-checkbox class="terms-agree" v-model="agreeCheckbox" @input="fn_OpenVnoUrl">본인확인 서비스 이용동의</mo-checkbox> <!-- [D] 미선택 시 class 추가 : error -->
        <router-link to="#" class="read-terms"></router-link>
      </div>
      <div class="wrap-table">
        <mo-validate-watcher ref="vWatcher">
          <table class="table">
            <tbody>
              <tr>
                <th>성명</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field v-model="custCardVO.custNm" placeholder="입력하세요" class="w245" :rules="validateRuleRequire" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>생년월일</th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field numeric v-model="custCardVO.knbFrno" mask="######" class="w130" placeholder="6자리" maxlength="6" :rules="validateRuleRequire" />
                    <mo-decimal-field numeric v-model="custCardVO.knbBkno" mask="#" class="mw30" placeholder="" maxlength="1" :rules="validateRuleRequire" />
                    <span class="idNum-skip">******</span>
                  </div>
                </td>
              </tr>
              <tr>
                <th>인증구분</th>
                <td>
                  <div class="wrap-input chkLeft" v-if="lv_partTyp === 'fs'">
                    <mo-checkbox class="type02" v-model="lv_athntData.id1.value" :disabled="lv_athntData.id1.disabled" @input="fn_SetAgrTime(1)"> {{lv_athntTxt.fs[0]}} </mo-checkbox>
                    <mo-checkbox class="type02" v-model="lv_athntData.id2.value" :disabled="lv_athntData.id2.disabled" @input="fn_SetAgrTime(2)"> {{lv_athntTxt.fs[1]}} </mo-checkbox>
                    <mo-checkbox class="type02" v-model="lv_athntData.id3.value" :disabled="lv_athntData.id3.disabled" @input="fn_SetAgrTime(3)"> {{lv_athntTxt.fs[2]}}</mo-checkbox>
                    <mo-checkbox class="type02" v-model="lv_athntData.id4.value" :disabled="lv_athntData.id4.disabled" @input="fn_SetAgrTime(4)"> {{lv_athntTxt.fs[3]}} </mo-checkbox>
                    <mo-checkbox class="type02" v-model="lv_athntData.id5.value" :disabled="lv_athntData.id5.disabled" @input="fn_SetAgrTime(5)"> {{lv_athntTxt.fs[4]}} </mo-checkbox>
                    <mo-checkbox class="type02" v-model="lv_athntData.id6.value" :disabled="lv_athntData.id6.disabled" @input="fn_SetAgrTime(6)"> {{lv_athntTxt.fs[5]}} </mo-checkbox>
                  </div>
                  <div class="wrap-input chkLeft" v-else>
                    <mo-checkbox class="type02" v-model="lv_athntData.id1.value" :disabled="lv_athntData.id1.disabled" @input="fn_SetAgrTime(1)"> {{lv_athntTxt.ts[0]}} </mo-checkbox>
                    <mo-checkbox class="type02" v-model="lv_athntData.id2.value" :disabled="lv_athntData.id2.disabled" @input="fn_SetAgrTime(2)"> {{lv_athntTxt.ts[1]}} </mo-checkbox>
                    <mo-checkbox class="type02" v-model="lv_athntData.id3.value" :disabled="lv_athntData.id3.disabled" @input="fn_SetAgrTime(3)"> {{lv_athntTxt.ts[2]}}</mo-checkbox>
                    <mo-checkbox class="type02" v-model="lv_athntData.id4.value" :disabled="lv_athntData.id4.disabled" @input="fn_SetAgrTime(4)"> {{lv_athntTxt.ts[3]}} </mo-checkbox>
                    <mo-checkbox class="type02" v-model="lv_athntData.id5.value" :disabled="lv_athntData.id5.disabled" @input="fn_SetAgrTime(5)"> {{lv_athntTxt.ts[4]}} </mo-checkbox>
                    <mo-checkbox class="type02" v-model="lv_athntData.id6.value" :disabled="lv_athntData.id6.disabled" @input="fn_SetAgrTime(6)"> {{lv_athntTxt.ts[5]}} </mo-checkbox>
                    <mo-checkbox class="type02" v-model="lv_athntData.id7.value" :disabled="lv_athntData.id7.disabled" @input="fn_SetAgrTime(7)"> {{lv_athntTxt.ts[6]}} </mo-checkbox>
                    <mo-checkbox class="type02" v-model="lv_athntData.id8.value" :disabled="lv_athntData.id8.disabled" @input="fn_SetAgrTime(8)"> {{lv_athntTxt.ts[7]}} </mo-checkbox>
                    <mo-checkbox class="type02" v-model="lv_athntData.id9.value" :disabled="lv_athntData.id9.disabled" @input="fn_SetAgrTime(9)"> {{lv_athntTxt.ts[8]}} </mo-checkbox>
                    <mo-checkbox class="type02" v-model="lv_athntData.id10.value" :disabled="lv_athntData.id10.disabled" @input="fn_SetAgrTime(10)"> {{lv_athntTxt.ts[9]}} </mo-checkbox>                    
                  </div>
                </td>                
              </tr>
              <tr>
                <th>통신사</th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown :items="telecomTypes" v-model="fn_TelecomType" class="w130" placeholder="선택" :rules="validateRuleRequire" ref="dropdown1" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>휴대폰</th>
                <td>
                    <div class="wrap-input row">
                      <mo-dropdown :items="phoneItems" v-model="custCardVO.celnoFrno" class="w73" placeholder=" " ref="dropdown2" />
                      <mo-decimal-field numeric v-model="custCardVO.celnoBkno1" mask="####" class="w80" placeholder=" " :rules="validateRuleCelno" />
                      <mo-decimal-field numeric v-model="custCardVO.celnoBkno2" mask="####" class="w80"  placeholder=" " :rules="validateRuleCelno" />
                      <mo-button @click="fn_BtnP1OnClick">
                        <span v-if="isBeforeSend">인증번호 전송</span>
                        <span v-else>인증번호 재전송</span>
                      </mo-button> 
                    </div>
                </td>
              </tr>
              <!-- 인증번호 전송 성공시 노출되는 인증번호입력 영역 (해당 영역이 노출되면 "인증번호 전송" 버튼은 "인증번호 재전송" 버튼으로 바뀜) -->
              <tr v-if="isCountStart">
                <th>인증번호</th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field numeric v-model="authNumber" mask="######" class="input-verifyNum" placeholder="입력하세요" clearable />
                    <mo-button @click="fn_PrcsMoblAthntCnf">인증확인</mo-button> <!-- 인증번호 맞을 시 테스트 : openModal(3) -->
                    <span class="verify-count" v-if="isCountStart">인증번호 만료까지 <b>{{ minutes | twoDigits }}:{{ seconds | twoDigits }}</b></span>
                    <!-- 인증번호 입력시간 초과시 .verify-count 가 사라지고 .error.timeover 가 나타남  -->
                    <!-- <span class="error timeover">인증시간이 만료되었습니다.<br/>인증번호를 재전송하세요.</span> -->
                  </div>
                </td>
              </tr>              
              <!-- //인증번호 전송시 노출되는 인증번호입력 영역 -->
            </tbody>
          </table>
        </mo-validate-watcher>
      </div>
      <div class="wrap-desc mt16">
        <p>인증번호 미도착시 아래와 같이 조치 부탁드립니다.</p>
        <ul>
          <li>1. 휴대폰 스팸번호 분류 확인</li>
          <li>2. NICE인증 고객센터문의 <em class="colorB">1600-1522</em></li>
        </ul>
      </div>
    </ur-box-container>

    <!-- alert modal popup -->
    <mo-modal 
      ref="alertPopup"
      class="fts-modal small"     
      :title="lv_alertPopupObj.title">
      
      <div class="column align-center">
        <h2 class="title" v-if="lv_alertPopupObj.contentTitle">{{ lv_alertPopupObj.contentTitle }}</h2>
        <span class="wrap-text">
          <span v-for="(row, i) in lv_alertPopupObj.content" :key="i">
          {{ lv_alertPopupObj.content[i] }}<br v-if="i < lv_alertPopupObj.content.length"/>
          </span>
        </span>
      </div>
      <template slot="action">
        <div class="wrap-button button-page-bottom row" v-if="lv_alertPopupObj.type === 0">
          <mo-button size="large" @click="fn_AlertPopupClose()">확인</mo-button>
        </div>
        <div class="wrap-button button-page-bottom row" v-if="lv_alertPopupObj.type === 1">
          <mo-button size="large" @click="fn_AlertPopupClose()">아니오</mo-button>
          <mo-button primary size="large" @click="fn_AlertPopupConfirm()">예</mo-button>
        </div>
      </template>
    </mo-modal>

    <mo-snackbar ref="stackableSnackbar" :duration="3000"></mo-snackbar>
  </ur-box-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSCommUtil from "~/src/ui/fs/comm/FSCommUtil";
import moment from 'moment'
import MSPCM010P from '@/ui/cm/MSPCM010P' // 알뜰폰 안내

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPFS510P",
  screenId: "MSPFS510P",
  components: {
    moment,    
    MSPCM010P,    
  },  
  props: {    
    pAthntData: {type:Object, default:null},
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {    
    this.fn_Init()
  },
  mounted() {
    this.$bizUtil.insSrnLog("MSPFS511D");
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // 휴대폰 탭
      phoneItems: [],
      date: 0,
      now: Math.trunc((new Date()).getTime() / 1000),
      aprvNoErrCnt: 0, // 인증 실패 횟수
      isCountStart: false,      
      interval: {},
      isAuthCompleted: false,
      isAuthError: false,
      telecomType: '',
      telecomTypes: [
        { value: 'SKT', text: 'SKT'},
        { value: 'KTF', text: 'KT' },
        { value: 'LGT', text: 'LG U+' },
        { value: 'SKTM',text: 'SKT(알뜰폰)' },
        { value: 'KTM', text: 'KT(알뜰폰)'  },
        { value: 'LGTM',text: 'LG U+(알뜰폰)'},
        { value: 'MCHK',text: '알뜰폰 확인하기'}
      ],
      prevTelecomType: '',
      custCardVO: {custNm: '', knbFrno: '', knbBkno: '', celno: '', celnoFrno: '',  celnoBkno1: '',  celnoBkno2: ''},
      agreeCheckbox: false,
      sCertVnoUrl: 'https://cert.vno.co.kr/app/agree/agree_main.jsp', // 이통사 본인확인 서비스 이용에 따른 동의 나이스 링크 URL
      agreeMsg:'', //모달 이용동의 내용
      authNumber: '',
      moblAthntCd: '', // 모바일인증코드
      moblAthntPswd: '', // 모바일인증비밀번호
      respUnqNo: '', // 응답고유번호
      reqUnqNo: '', // 요청고유번호
      authNumberDisabled: true,
      selfAthntUsScCd: '01', // 본인인증용 구분코드
      validateRuleRequire: [
        v => !!v || '필수입력 항목입니다',
      ],      
      validateRuleCelno: [
        v => !!v || '필수입력 항목입니다',
        v => v.length > 3 || '휴대폰번호를 입력하세요.'
      ],      
      isBeforeSend: true,
      contentSendMsg: '인증번호가 전송되었습니다.',      
      lv_alertPopupObj: {},      
      lv_athntData: {},  // 인증구분 설정
      lv_partTyp: '' , // 업무구분
      lv_athntTxt: {
        fs: ['고객','대리인','조력자','은행계좌주','친권자1','친권자2'],
        ts: ['위탁자(고객)','수익자(원금)','수익자(이익)','대리인','대리인2','조력자','신탁관리인','1차수익자(원금)','1차수익자(이익)','지급청구대리인'],
      }
    };
  },
  filters: {
    twoDigits: function (value) {
      if (value.toString().length <= 1) {
        return '0' + value.toString()
      }
      return value.toString()
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    // modal        
    modalSendAgree() {return this.$refs.sendAgree},   // 동의서 안내
    fn_TelecomType: { //통신사
      get: function () {
        return this.telecomType
      },
      set: function (data) {
        this.prevTelecomType = this.telecomType // 현재 선택되어 있는 값 복사
        this.telecomType = data
        // this.fn_NextComp('celnoRef')
      }
    },
    modal(){
      return this.$refs.modalFrm
    },
    seconds () {
      let lv_Seconds = (this.remainDateValue - this.now) % 60
      if (lv_Seconds > 0) {
        return (this.remainDateValue - this.now) % 60
      } else {
        if (this.minutes === 0) {
          window.clearInterval(this.interval) // 타이머 중지
        }
        return 0
      }
    },
    minutes () {
      let lv_Minutes = Math.trunc((this.remainDateValue - this.now) / 60) % 60
      if (lv_Minutes > 0) {
        return Math.trunc((this.remainDateValue - this.now) / 60) % 60
      } else {
        return 0
      }
    },
    remainDateValue () {
      return Math.trunc(this.date / 1000)
    },    
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    telecomType (value) {
      if (value === 'MCHK') { // 알뜰폰 확인하기 선택 시 팝업창 호출 (MSPCM010P 팝업)
        this.popup010 = this.$bottomModal.open(MSPCM010P, {
          properties: {
            pCustNm: this.custCardVO.custNm // 고객명 파라미터
          },
          listeners: {
            confirmPopup: (pData) => {
              this.$bottomModal.close(this.popup010)
              
              // 파라미터 세팅
              this.fn_ConfirmMSPCM010P(pData)
            }
          }
        })
      }
    },
    agreeCheckbox: function (val) {
      if (val) this.fn_AgreePopContent()
    },    
    'lv_athntType': {
      handler(after, before) {
        console.log('lv_athntType > ', after)        
      },
      deep: true
    },
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_SetAgrTime(idx) {
      const athntData = this.lv_athntData['id' + String(idx)]
      const mthTxt = '휴대폰'
      if(athntData.value) {
        let curDt = moment(new Date().toISOString()).format("YYYY년MM월DD일 HH:mm:ss")
        athntData.text = athntData.type + '(은)는 ' + curDt + '에 ' + mthTxt + '(으)로 인증하였습니다.'
        console.log('fn_SetAgrTime > ', athntData.text)
      } else {
        athntData.text = ''
      }
    },
    fn_ValidationInput() {
      console.log('fn_ValidationInput - telecomType >', this.telecomType)
      this.$refs.vWatcher.validate();      
      if (!this.$refs.vWatcher.isValid() === true) {
        this.$addSnackbar({text: this.$refs.vWatcher.validate(), error: !this.$refs.vWatcher.isValid()});
        return false
      }
      return true
    },
    addSnackbar() {
      this.$refs.stackableSnackbar.show({text:this.content});
    },
    // modal
    openModal(type) {
      this["modal"+type].open();
    },
    closeModal(type) {
      this["modal"+type].close();       
    },
    closeDropdown() {
      this.$refs.dropdown1.close()
      this.$refs.dropdown2.close()
    },
    verifySend() {
      this.valueVerifySend = true
    },
    verifyOk() {
      this.valueVerifyOk = true
    },
    fn_Validate_Inpt(msg) {
      let t_msg = '미입력된 필수 항목이 있습니다.'
      if(msg){
        t_msg = msg
      }
      this.$addSnackbar({text: t_msg, error: true});
    },
    fn_AlertPopup(obj) {
      this.lv_alertPopupObj = {}
      this.lv_alertPopupObj.type = obj.type
      this.lv_alertPopupObj.title = ''        // 사용안함    
      this.lv_alertPopupObj.contentTitle = '' // 사용안함
      this.lv_alertPopupObj.content = obj.content
      this.lv_alertPopupObj.closeFunc = function () {}
      this.lv_alertPopupObj.confirmFunc = function () {}      
      if(obj.type === 1){
        this.lv_alertPopupObj.closeFunc = obj.closeFunc
        this.lv_alertPopupObj.confirmFunc = obj.confirmFunc
      }
      this.$refs.alertPopup.open()
    },
    fn_AlertPopupClose() {
      if(!FSCommUtil.gfn_isNull(this.lv_alertPopupObj.closeFunc)){
        this.lv_alertPopupObj.closeFunc.call(this)
      }
      this.$refs.alertPopup.close()
    },
    fn_AlertPopupConfirm() {
      if(!FSCommUtil.gfn_isNull(this.lv_alertPopupObj.confirmFunc)){
        this.lv_alertPopupObj.confirmFunc.call(this)
      }
      this.$refs.alertPopup.close()
    },
    /*********************************************************
       * Function명: fn_AddAthnt
       * 설명: 추가본인증
       * Params: N/A
       * Return: N/A
       *********************************************************/
    fn_AddAthnt() {      
      let lv_Vm = this
      let t_popupObj = {
        type : 1,                
        confirmFunc: function () {
          lv_Vm.fn_Init(1) // 추가 본인인증
        },
        closeFunc: function () {
          lv_Vm.$emit('setFS511D', 2, lv_Vm.lv_athntData) // 인증완료 처리
        },
        content: ['인증되었습니다.', '추가로 본인인증을 진행하시겠습니까?']                
      }              
      lv_Vm.fn_AlertPopup(t_popupObj)
    },
    /*********************************************************
       * Function명: fn_Init
       * 설명: 로딩시 정보항목 초기화 및 설정
       * Params: type (0: 변수 초기화, 1: 추가인증, 2: 탭 이동) , pData (인증 데이터)
       * Return: N/A
       *********************************************************/
    fn_Init (type = 0, pParam = null) {
      console.log('[MSPFS511D] fn_Init...')
      let lv_Vm = this
      let tmpAthntData = {}     

      if(type == 0) { // 초기화
        this.lv_partTyp = this.$route.name.substr(3,2).toLowerCase()
        tmpAthntData = {}
        let startIdx = 1
        let endIdx = this.lv_partTyp === 'fs' ? 6 : 10
        let athntTxt = this.lv_partTyp === 'fs' ? this.lv_athntTxt.fs : this.lv_athntTxt.ts
        for(let i = startIdx ; i <= endIdx ; i++){
          tmpAthntData['id' + String(i)] = FSCommUtil.gfn_dataSet(3)
          tmpAthntData['id' + String(i)].type = athntTxt[i - 1]
          tmpAthntData['id' + String(i)].text = ''
        }
        lv_Vm.lv_athntData = Object.assign({}, tmpAthntData)
        lv_Vm.$emit('setFS511D', 0, lv_Vm.lv_athntData)
      } else if(type == 1) { // 추가 본인인증인 경우
        let obj = {}        
        for(const item in lv_Vm.lv_athntData){          
          obj = lv_Vm.lv_athntData[item]
          if(obj.value) {
            console.log('obj.value = true >', obj)
            obj.disabled = true
          }          
        }
        lv_Vm.$emit('setFS511D', 1, lv_Vm.lv_athntData)
      } else if(type == 2) { // 탭 이동 시
        if(this.$bizUtil.isEmpty(pParam)) {
          this.fn_Init()
          return
        }
        console.log('fn_Init - pParam : ', pParam)
        let obj = {}        
        lv_Vm.lv_athntData = lv_Vm.pAthntData
        for(const item in lv_Vm.lv_athntData){          
          obj = lv_Vm.lv_athntData[item]
          if(obj.value) {
            console.log('obj.value = true >', obj)
            obj.disabled = true
          }          
        }
      }

      const tmpCustCardVO = 
      { 
        custNm: '', 
        knbFrno: '', 
        knbBkno: '', 
        celno: '', 
        celnoFrno: '',  
        celnoBkno1: '',  
        celnoBkno2: ''
      }
      
      // 데이터 초기화
      lv_Vm.custCardVO = Object.assign({}, tmpCustCardVO)
      lv_Vm.telecomType = ''
      lv_Vm.agreeCheckbox = false
      if(lv_Vm.$refs.vWatcher){
        lv_Vm.$refs.vWatcher.validate()
        if (!lv_Vm.$refs.vWatcher.isValid() === true) {
          lv_Vm.$refs.vWatcher.clearValidation()
        }
      }

      // ---------------------------------------------------------------------------------
      // 공통업무코드 조회 및 설정 처리
      // ---------------------------------------------------------------------------------
      window.vue.getInstance('vue').$commonUtil.selListMassEACommCd([
          'ZA_ELTRN_MAL_DM_CD', // 이메일도메인 코드정보
        'ZA_CELPH_KNB_CD' // 휴대전화국번 코드정보
      ]).then(function () {
        // 휴대전화 앞자리 정보 세팅
        let lv_CelphCD = lv_Vm.getStore('code').getters.getState.get('ZA_CELPH_KNB_CD')

        lv_Vm.phoneItems.push({value: '0', text: '선택'})
        if (lv_CelphCD.length > 0) {
          lv_CelphCD.forEach(
            (item) => {
              let lv_Item = {}
              lv_Item.value = item.key
              lv_Item.text = item.key
              lv_Vm.phoneItems.push(lv_Item)
            }
          )
        }

        // 휴대폰번호 앞자리 초기값은 '010'
        lv_Vm.custCardVO.celnoFrno = lv_Vm.phoneItems[1].value         

        // lv_Vm.$nextTick(() => {
        //   lv_Vm.$refs['custNmRef'].focus()
        // })
      })
    },
    /******************************************************************************
     * Function명 : fn_OpenVnoUrl
     * 설명       : 이통사 본인확인 서비스 이용에 따른 동의 나이스 링크 URL
     ******************************************************************************/
    fn_OpenVnoUrl(value){
      console.log('vno url value : ', value)
      let url = 'https://cert.vno.co.kr/app/agree/agree_main.jsp'
      if( value ){
        if (this.$commonUtil.isMobile()) {
          window.fdpbridge.exec('intentBrowserPlugin', {uri: url}, () => {}, () => {})
        } else { // test pc에서 작동
          window.open(url, '_blank')
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_ConfirmMSPCM010P
    * 설명       : 알뜰폰 안내 팝업창의 '확인', '취소' 버튼 클릭 시 호출
    ******************************************************************************/
    fn_ConfirmMSPCM010P (param) {
      if (param !== null && param !== '') {
        this.telecomType = param.val
      } else { // 취소버튼 클릭 시
        this.telecomType = this.prevTelecomType
      }
    },
    /******************************************************************************
    * Function명 : fn_AgreePopContent
    * 설명       : 이통사 본인확인 서비스 이용에 따른 동의 나이스 링크 호출
    ******************************************************************************/
    fn_AgreePopContent () {
      let lv_Url = this.sCertVnoUrl        
      if (this.$commonUtil.isMobile()) { // 모바일에서 작동
        window.fdpbridge.exec('intentBrowserPlugin', {uri: lv_Url}, () => {}, () => {})
      } else { // PC에서 작동
        window.open(lv_Url, '_blank')
      }
      this.agreeCheckbox = true
    },
    /******************************************************************************
    * Function명 : fn_AgreeConfirmYes
    * 설명       : 본인확인 서비스 이용 안내 confirm팝업창의 '동의' / '동의안함' 버튼 선택 시
    ******************************************************************************/
    fn_AgreeConfirmYes () {          
        this.fn_AgreePopContent()
        // this.modalSendAgree.close()
    },
    /******************************************************************************
     * Function명: fn_OnNegative
     * 설명: Confirm창 취소 버튼 클릭 함수
     ******************************************************************************/
    fn_OnNegative () {
      // '취소' 선택시 vuex 에 confirm 창을 닫았다고 저장한다.
      this.getStore('spStore').dispatch('SMS_CONFIRM', 'CLOSE')
      // confirm 창 닫음
      this.modal.close()
    },
    /******************************************************************************
    * Function명 : fn_ValidationAgree
    * 설명       : 인증전송 버튼 클릭 시 유효성 체크
    ******************************************************************************/
    fn_ValidationAgree () {
      // 동의 체크 여부 확인
      let lv_RetVal = true        
      if (this.agreeCheckbox === false) {                     
        // 본인확인 서비스 이용동의 모달창 띄움.          
        lv_RetVal = false
        return lv_RetVal
      }
      return lv_RetVal
    },
    /******************************************************************************
    * Function명 : fn_ValidationNumCheck
    * 설명       : 인증번호 버튼 클릭 시 유효성 체크
    ******************************************************************************/
    fn_ValidationNumCheck () {
      var lv_AgreeMsg = true        
      if (this.$bizUtil.isEmpty(this.telecomType)) {
        // this.getStore('confirm').dispatch('ADD', '통신사를 선택하세요')
        // console.log('통신사 미선택!')
        this.fn_Validate_Inpt('통신사를 선택하세요')
        lv_AgreeMsg = false
        return lv_AgreeMsg
      }

      if (this.custCardVO.celnoFrno === '0') { // 핸드폰앞자리가 선택으로 되어 있는 경우
        // this.getStore('confirm').dispatch('ADD', '핸드폰 앞자리를 선택하세요')          
        this.fn_Validate_Inpt('핸드폰 앞자리를 선택하세요')
        lv_AgreeMsg = false
        return lv_AgreeMsg
      }
      
      return lv_AgreeMsg
    },
    
    /******************************************************************************
    * Function명 : fn_BtnP1OnClick
    * 설명       : 핸드폰 인증번호 요청
    ******************************************************************************/
    fn_BtnP1OnClick () {
      // 동의 체크
      let agreeCheck = this.fn_ValidationAgree()
      if (!agreeCheck) {
        this.fn_AgreePopContent()        
        return
      }
      
      let verifyCheck = this.fn_ValidationInput()
      if (!verifyCheck) {
        return
      }

      let lv_Vm = this
      let lv_Msg = this.fn_ValidationNumCheck()
      if (lv_Msg) {
        lv_Vm.isCountStart = false // 타이머 초기화
        lv_Vm.isAuthError = false // 인증실패 초기화
        lv_Vm.isAuthCompleted = false // 인증여부 초기화
        lv_Vm.authNumber = '' // 인증번호 초기화
        let tmpKnb = lv_Vm.custCardVO.knbFrno + lv_Vm.custCardVO.knbBkno + '000000'

        if (lv_Vm.custCardVO.celnoFrno === '0') { // 핸드폰앞자리가 선택으로 되어 있는 경우
          lv_Vm.custCardVO.celnoFrno = lv_Vm.phoneItems[1].value
        }

        lv_Vm.custCardVO.celno = lv_Vm.custCardVO.celnoFrno + lv_Vm.custCardVO.celnoBkno1 + lv_Vm.custCardVO.celnoBkno2
        
        let jobTyp = lv_Vm.$route.name.substr(3,2).toUpperCase()
        console.log('MSPFS511D - fn_BtnP1OnClick', jobTyp)
        let pParams = {
          custNm: lv_Vm.$bizUtil.isEmpty(lv_Vm.custCardVO.custNm) ? '' : lv_Vm.custCardVO.custNm, // 고객명
          selfAthntUsScCd: lv_Vm.selfAthntUsScCd, // 본인인증용도구분코드
          cmmuScCd: lv_Vm.telecomType === 'KTF' ? 'KT' : lv_Vm.telecomType, // 통신사
          // cmmuScCd: lv_Vm.telecomType.key, // 통신사
          // celno: lv_Vm.mobileNumber, // 핸드폰 번호
          celno: lv_Vm.custCardVO.celno.replace(/\-/g, ''),
          knb: lv_Vm.$bizUtil.isEmpty(jobTyp) ? '' : jobTyp,          
          cnsltNo: lv_Vm.custCardVO.cnsltNo, // 컨설턴트 사번
          rrn: tmpKnb
        }

        let trnstId = 'txTSSFS03P1'
        // let trnstId = 'txTSSFS99S2'
        this.post(lv_Vm, pParams, trnstId, 'S')
          .then(function (response) {
            if (response.body !== null && response.body !== '' && response.body.rsltCd === '0000') {
              let lv_TmpResult = response.body
              if (lv_TmpResult) {
                // lv_Vm.selfAthntRespUnQVal = lv_TmpResult.selfAthntRespUnQVal
                // lv_Vm.nicePtptInstId = lv_TmpResult.nicePtptInstId
                lv_Vm.moblAthntCd = lv_TmpResult.moblAthntCd // 모바일인증코드
                lv_Vm.moblAthntPswd = lv_TmpResult.moblAthntPswd // 모바일인증비밀번호
                lv_Vm.respUnqNo = lv_TmpResult.respUnqNo // 응답고유번호
                lv_Vm.reqUnqNo = lv_TmpResult.reqUnqNo // 요청고유번호

                // 타이머 세팅 및 시작
                lv_Vm.isCountStart = true
                lv_Vm.date = moment(new Date()).add(7, 'm').toDate() // 인증 유효 시간을 7분으로 timer 세팅
                lv_Vm.interval = window.setInterval( () => {
                  lv_Vm.now = new Date(Date.now() + (new Date().getTimezoneOffset() * 60000)).getTime() / 1000 | 0
                }, 1000)

                lv_Vm.authNumberDisabled = false // 인증번호 Text-Input 활성화

                lv_Vm.aprvNoErrCnt = 0 // 실패 횟수는 다시 0으로 초기화
                
                lv_Vm.$addSnackbar({text: lv_Vm.contentSendMsg, error: false})                
              }
            } else { // 정상처리가 아닌경우              
              lv_Vm.fn_AlertPopup({type: 0, content: [response.msgComm.msgDesc]})
              // lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
            if (!_.isEmpty(error.message)) {
              // lv_Vm.getStore('confirm').dispatch('ADD', error.message)              
              lv_Vm.fn_AlertPopup({type: 0, content: [error.message]})
            } else {                            
              lv_Vm.fn_AlertPopup({type: 0, content: ['시스템 오류가 발생했습니다.','관리자에게 문의하여 주세요.']})
              // lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('rc')['ERCC001']) // 시스템 오류가 발생했습니다. \n관리자에게 문의하여 주세요.
            }
          })
      }
    },
    /******************************************************************************
    * Function명 : fn_PrcsMoblAthntCnf
    * 설명       : 핸드폰 인증번호 확인
    ******************************************************************************/
    fn_PrcsMoblAthntCnf () {
      let lv_Vm = this
      let lv_RetVal = true
      if (_.isEmpty(this.authNumber) || this.authNumber.length !== 6) {        
        lv_Vm.fn_AlertPopup({type: 0, content: ['인증번호 정보가 정확하지 않습니다.']})
        lv_RetVal = false
      }      
      if (!lv_RetVal) {
        return
      }

      if (!lv_Vm.agreeCheckbox) {
        lv_Vm.fn_AlertPopup({type: 0, content: ['본인확인 서비스 이용동의를 체크해주세요.']})
        return
      }

      let tmpKnb = lv_Vm.custCardVO.knbFrno + lv_Vm.custCardVO.knbBkno + '000000'
      let jobTyp = lv_Vm.$route.name.substr(3,2).toUpperCase()
      console.log('MSPFS511D - fn_PrcsMoblAthntCnf', jobTyp)

      // let trnstId = 'txTSSCM12S0'
      lv_Vm.aprvNoErrCnt++ // 인증번호 전송 횟수 체크 (3회까지만 허용됨)
      let pParams = {
        custId: lv_Vm.$bizUtil.isEmpty(lv_Vm.custCardVO.custId) ? '' : lv_Vm.custCardVO.custId, // 고객ID
        custNm: lv_Vm.$bizUtil.isEmpty(lv_Vm.custCardVO.custNm) ? '' : lv_Vm.custCardVO.custNm, // 고객명
        selfAthntUsScCd: lv_Vm.selfAthntUsScCd, // 본인인증용도구분코드
        celno: lv_Vm.custCardVO.celno.replace(/\-/g, ''), // 핸드폰 번호
        knb: lv_Vm.$bizUtil.isEmpty(jobTyp) ? '' : jobTyp, // CM
        rrn: tmpKnb,
        moblAthntCd: lv_Vm.moblAthntCd, // 모바일인증코드
        moblAthntPswd: lv_Vm.moblAthntPswd, // 모바일인증비밀번호
        reqUnqNo: lv_Vm.reqUnqNo, // 요청고유번호
        respUnqNo: lv_Vm.respUnqNo, // 응답고유번호
        athntNo: lv_Vm.authNumber, // 인증번호
        aprvNoErrCnt: lv_Vm.aprvNoErrCnt, // 인증번호 오류 횟수
        cnsltNo: lv_Vm.custCardVO.cnsltNo, // 컨설턴트 사번
        chnlCustId: lv_Vm.custCardVO.chnlCustId // 채널고객ID
      }

      let trnstId = 'txTSSFS03P2'
      // let trnstId = 'txTSSFS99S3'
      this.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          if (response.body !== null && response.body !== '' && response.body.rsltCd === '0000') {
            let lv_TmpResult = response.body
            if (lv_TmpResult) {
              window.clearInterval(lv_Vm.interval) // 타이머 종료
              lv_Vm.isCountStart = false
              lv_Vm.isAuthError = false
              lv_Vm.isAuthCompleted = true
              lv_Vm.authNumberDisabled = true
              let t_popupObj = {
                type : 1,                
                confirmFunc: function () {
                  lv_Vm.fn_Init(1) // 추가 본인인증
                },
                closeFunc: function () {                  
                  lv_Vm.$emit('setFS511D', 2, lv_Vm.lv_athntData) // 인증완료
                },
                content: ['인증되었습니다.', '추가로 본인인증을 진행하시겠습니까?']                
              }              
              lv_Vm.fn_AlertPopup(t_popupObj)
            }
          } else { // 정상처리가 아닌경우            
            // lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)            
            lv_Vm.fn_AlertPopup({type: 0, content: ['인증번호가 일치하지 않습니다.']})
            lv_Vm.isAuthError = true
            lv_Vm.isAuthCompleted = false            
            if (response.msgComm.msgCd === 'ECMU001') { // 인증번호 3회 오류 시
              window.clearInterval(lv_Vm.interval) // 타이머 종료
              lv_Vm.isCountStart = false
              lv_Vm.authNumberDisabled = true // 인증번호 Text-Input 비활성화
              lv_Vm.authNumber = '' // 인증번호 초기화
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
          if (!_.isEmpty(error.message)) {            
            lv_Vm.fn_AlertPopup({type: 0, content: [error.message]})
            // lv_Vm.getStore('confirm').dispatch('ADD', error.message)
          } else {
            lv_Vm.fn_AlertPopup({type: 0, content: ['시스템 오류가 발생했습니다.','관리자에게 문의하여 주세요.']})            
            // lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('rc')['ERCC001']) // 시스템 오류가 발생했습니다. \n관리자에게 문의하여 주세요.
          }
        })
    },
    /******************************************************************************
    * Function명 : fn_AuthCompleted
    * 설명       : 고객등록페이지 이동
    ******************************************************************************/
    fn_AuthCompleted () {
      let tmpCelno = this.custCardVO.celnoFrno + this.custCardVO.celnoBkno
      this.custCardVO.celno = tmpCelno.replace(/\-/g, ''), // 핸드폰 번호
      this.$router.push( { name: 'MSPRC150M', params: { custCardVO: this.custCardVO} } )
    },
  }
};
</script>
<style scoped>
</style>
